import React, { useState } from 'react'
import IMAGES from '../Middleware/images'
import apiHelper from '../Helper/api-helper';
import ERR_MESSAGE from '../Helper/error-helper';
import DEVELOPMENT_CONFIG from "../Helper/config"
import ReactLoader from '../ControlComponents/react-loader';
import { Helmet } from 'react-helmet';
import titles from '../Helper/title';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../Middleware/routes';
import "../Assets/Styles/forgot-password.css"
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const ForgotPassword = () => {
  const [errors, setErrors] = useState("");
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    password1: "",
    password2: ""
  });
  const handleChange = (e) => {
    setErrors("");
    const name = e.target.name;
    const value = e.target.value;
    setFields({
      ...fields,
      [name]: value,
    });
  };
  const [eye_icon1, setEye_icon1] = useState(IMAGES.EYE_HIDE);
  const [type1, setType1] = useState("password");
  const [msg1, setMsg1] = useState("reveal password")
  const [eye_icon2, setEye_icon2] = useState(IMAGES.EYE_HIDE);
  const [type2, setType2] = useState("password");
  const [msg2, setMsg2] = useState("reveal password")
  const showPassword = () => {
    if (type1 === "password") {
      setType1("text");
      setEye_icon1(IMAGES.EYE_SHOW);
      setMsg1("hide password")
    }
    else {
      setType1("password");
      setEye_icon1(IMAGES.EYE_HIDE);
    }
  }
  const showConfirmPassword = () => {
    if (type2 === "password") {
      setType2("text");
      setEye_icon2(IMAGES.EYE_SHOW);
      setMsg2("hide password")
    }
    else {
      setType2("password");
      setEye_icon2(IMAGES.EYE_HIDE);
    }
  }
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
    const { password1, password2 } = fields;
    if (password1.trim() == "") {
      formIsValid = false
      errors["password1"] = ERR_MESSAGE.PASSWORD_NEW_EMPTY
    } 
    else if (passwordReg.test(password1) === false) {
      formIsValid = false
      errors["password1"] = ERR_MESSAGE.INVALID_PASSWORD
    }
    if (password2.trim() == "") {
      formIsValid = false
      errors["password2"] = ERR_MESSAGE.EMPTY_CONFIRMPASSWORD
    }
    else if (password2.trim() !== password1.trim()) {
      formIsValid = false
      errors["password2"] = ERR_MESSAGE.NOTMATCHED
    }
    setErrors(errors);
    return formIsValid;
  };
  const success = (msg) =>{
    toast.success(msg,
    {
      autoClose: 5000, 
    });
  }
  const error = (msg) =>{
    toast.success(msg,
    {
      autoClose: 5000, 
    });
  }
  async function postAPI(e) {
    e.preventDefault();
    if (handleValidation()) {
      const searchParams = new URLSearchParams(location.search);
      const resetPasswordToken = searchParams.get('token');
      if(resetPasswordToken){
        let data = JSON.stringify({
          "token": resetPasswordToken,
          "password": fields.password2,
        });
        setLoading(true);
        let result = await apiHelper.postRequest("auth/reset-passsword", data)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
          success(result.message)
          setLoading(false);
          // navigate(ROUTES.SIGN_IN);
          setTimeout(() => {
            navigate(ROUTES.SIGN_IN);
          }, 2500); // 2000ms = 2 seconds
        } else {
          error(result.message)
          setLoading(false);
        }
      }
      else{
        setLoading(false);
        // alert("Either no token or wrong token");
      }
     
    }
  }
  return (
    <>
          <Helmet>
        <title>{titles.forgotPassword}</title>
      </Helmet>
      <div className='loader'>
        {isLoading && <ReactLoader />}
      </div>
      <section className="signin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-6 p-0 signin-img forgetimg">
              <div className="signin-img forgetimg">
                <img src={IMAGES.FORGOT_PASSWORD} alt="img-fluid" className="cus-img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-6 p-0">
              <div className="signin-main">
                <div className="signin-inner">
                  <div className="signin-logo text-center pb-4">
                    <img src={IMAGES.HAPTAPT_LOGO} alt="signin-image" className="img-fluid" />
                  </div>
                  <div className="signin-head forgot-head text-center pb-3">
                    <h1>Forgot your password?</h1>
                  </div>
                  <div className="signin-form text-center">
                    <form onSubmit={postAPI}>
                      
                      <div className="mb-3 mb-xxl-5 text-end position-relative forgot-input">
                                            <TextField type={type1} fullWidth label="New password" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="password1" onChange={handleChange}  value={fields.password1.trim()} />
                        <img src={eye_icon1} alt="" style={{cursor: "pointer"}}  onClick={showPassword} className="hiden-eye" title={msg1} />
                      { errors.password1 && <span className="err_msg">{errors.password1}</span>}
                      </div>
                      <div className="mb-2 mb-xl-4 text-end position-relative forgot-input">
                                            <TextField type={type2} fullWidth label="Enter it again" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="password2" onChange={handleChange}  value={fields.password2.trim()} />
                       
                        <img src={eye_icon2} onClick={showConfirmPassword} alt="" style={{cursor: "pointer"}}  className="hiden-eye" title={msg2} />
                      {  errors.password2 && <span className="err_msg">{errors.password2}</span>}
                      </div>

                      <div className="text-center position-relative submit-head">
                        <button type="submit" className="yellow-btn d-flex align-items-center">Reset <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2"/></button>
                      </div>

                      <div className="get-in mt-3">
                        <Link to={ROUTES.SIGN_IN} className="forgot-link mt-1">Did you just get it? <span className="pink-text">Sign in</span></Link>
                      </div>
                    </form>
                    <ToastContainer rtl/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
