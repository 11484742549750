
import loginScreen from "../Assets/Images/Login_Screen.png";
import arrowBlack from "../Assets/Images/arrow_black.svg";
import eyeHide from "../Assets/Images/eye_hide_icon.svg";
import ForgotPassword from "../Assets/Images/Forget_password.png";
import haptaptLogo from "../Assets/Images/haptapt_logo.png";
import openEye from "../Assets/Images/open-eye.png";
import arrowGrey from "../Assets/Images/arrow-grey.svg";
import searchIcon from "../Assets/Images/search-icon.svg"
import haptaptBlack from "../Assets/Images/haptapt_black.png";
import notification from "../Assets/Images/notification.svg";
import userProfile from "../Assets/Images/user_profile.svg"
import trashIcon from "../Assets/Images/trash-icon.svg"
import editIcon from "../Assets/Images/edit-icon.svg"
import threeDots from "../Assets/Images/3dots-icon.svg"
import cross from "../Assets/Images/cross.svg"
import cross1 from "../Assets/Images/cross1.svg"
import companyLogo from "../Assets/Images/company_logo.png"
import smallArrow from "../Assets/Images/small_arrow.svg"
import pdfIcon from "../Assets/Images/pdf_icon.svg"
import backArrow from "../Assets/Images/back-arrow.svg"
import modalCross from "../Assets/Images/modal_cross.svg"
import dummyImage from "../Assets/Images/dummy-img.png"
import dropdownIcon from "../Assets/Images/dropdown-icon.svg"
import activeUser from "../Assets/Images/activeUser.svg"
import leftArrow from "../Assets/Images/left-arrow.svg"
import maskGroup from "../Assets/Images/Mask group.png"
import tickSquare from "../Assets/Images/tick-square.png"
import checkBoxTick from "../Assets/Images/check_box_tick.svg"
import chipCross from "../Assets/Images/chipCross.svg"
import checkButton from "../Assets/Images/check_btn_img.svg"
import cardChip from "../Assets/Images/card_chip.png"
import visaImage from "../Assets/Images/visa_img.png"
import triangleDropdown from "../Assets/Images/dropdown_triangle.svg"
import yellowDrop from "../Assets/Images/yellow_drop.svg"
import errorImg from "../Assets/Images/error.svg"
import noData from "../Assets/Images/no_data.svg"
import yellow_haptap from "../Assets/Images/yellow_haptap.png"
import facebook_icon from "../Assets/Images/facebook_icon.svg"
import twitter_icon from "../Assets/Images/insta_icon.svg"
import linkedin_icon from "../Assets/Images/linkdin_icon fill.svg"
import join_logo from "../Assets/Images/join_us_logo.png"
import Calendar from "../Assets/Images/calender.svg"
import Upload from "../Assets/Images/upload-square-icon.svg"
import instagram from "../Assets/Images/insta_icon stock.svg"
import newLoginImg from "../Assets/Images/newHaptapt.jpg"
import newLogoImg from "../Assets/Images/newLogoImg.png"
import backgroundLogo from "../Assets/Images/backgroundLogo.jpg"
import haptapt_thumbnail from "../Assets/Images/Haptapt_Thumbnail.png"
import profile_confirmation from "../Assets/Images/profile_confirmation.png"
import profile_support from "../Assets/Images/profile_support.png"


const IMAGES = {
    ARROW_BLACK: arrowBlack,
    EYE_HIDE: eyeHide,
    EYE_SHOW: openEye,
    FORGOT_PASSWORD: ForgotPassword,
    HAPTAPT_LOGO: haptaptLogo,
    LOGIN_SCREEN: loginScreen,
    ARROW_GREY: arrowGrey,
    SEARCH_ICON: searchIcon,
    HAPTAPT_BLACK: haptaptBlack,
    NOTIFICATION: notification,
    USER_PROFILE: userProfile,
    TRASH_ICON: trashIcon,
    EDIT_ICON: editIcon,
    THREE_DOTS_ICONS: threeDots,
    CROSS: cross,
    CROSS_1: cross1,
    COMPANY_LOGO: companyLogo,
    SMALL_ARROW: smallArrow,
    PDF_ICON: pdfIcon,
    BACK_ARROW: backArrow,
    MODAL_CROSS: modalCross,
    DUMMY_IMAGE: dummyImage,
    DROPDOWN_ICON: dropdownIcon,
    ACTIVE_USER: activeUser,
    LEFT_ARROW: leftArrow,
    MASK_GROUP: maskGroup,
    TICK_SQUARE: tickSquare,
    CHECK_BOX_TICK: checkBoxTick,
    CHIP_CROSS: chipCross,
    CHECK_BUTTON: checkButton,
    CARD_CHIP: cardChip,
    VISA_IMG: visaImage,
    TRIANGLE_DROPDOWN: triangleDropdown,
    YELLOW_DROP: yellowDrop,
    ERROR_IMG: errorImg,
    NO_DATA: noData,
    YELLOW_HAPTAPT: yellow_haptap,
    FACEBOOK_ICON: facebook_icon,
    TWITTER: twitter_icon,
    LINKEDIN: linkedin_icon,
    JOIN_LOGO: join_logo,
    CALENDER: Calendar,
    UPLOAD: Upload,
    INSTAGRAM :instagram,
    NEW_LOGIN_IMG: newLoginImg,
    NEW_LOGO_IMG :newLogoImg,
    BACKGROUND_LOGO: backgroundLogo,
    HAPTAPT_THUMBNAIL: haptapt_thumbnail,
    PROFILE_CONFIRMATION:profile_confirmation,
    PROFILE_SUPPORT:profile_support
}

export default IMAGES;