import React, { useEffect } from 'react';
import { SignIn } from "./Public/sign-in"
import Layout from './layout';
import { ForgotPassword } from "./Public/forgot-password";
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import OrganizationMember from './Private/SuperAdmin/organization-member';
import ROUTES from './Middleware/routes';
import Category from './Private/SuperAdmin/category';
import OrganizationList from './Private/SuperAdmin/organization-list';
import StepForm from './Public/step-form';
import Billing from './Private/SuperAdmin/billing';
import Profile from './Private/SuperAdmin/profile';
import SearchDatabase from './Private/User/search-database';
import SearchResult from './Private/User/search-result';
import UserProfile from './Private/User/user-profile';
import OrganizationCategory from './Private/Admin/category-organization';
import OrganizationSetting from './Private/Admin/setting-organization';
import EmployeesList from './Private/Admin/employees-list';
import EmployeeProfile from './Private/Admin/profile-employee';
import SearchEmployeeDatabase from './Private/Admin/database-search-employee';
import SearchEmployeesResult from './Private/Admin/result-search-employees';
import SettingBilling from './Private/Admin/setting-billing';
import './App.css';
import './font/AvenirLTStd-Book.woff';
import './font/AvenirLTStd-Medium.woff';
import './font/AvenirLTStd-Roman.woff';
import ErrorPage from './Public/error-page';
import NoData from './Public/no-data';
import { QueryClient, QueryClientProvider } from 'react-query';
import CustomRequest from './Private/SuperAdmin/custom';
import ProfileComponent from './Components/profileComponent';
import $ from 'jquery';
import ProfileConfirmation from './Private/Admin/profile-confirmation';


const SuperadminPrivateRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.CATEGORY} element={<Category />} />
      <Route path={ROUTES.ORGANIZATION_LIST} element={<OrganizationList />} />
      <Route path={ROUTES.ORGANIZATION_LIST2} element={<OrganizationList />} />
      <Route path={ROUTES.ORGANIZATION_MEMBER} element={<OrganizationMember />} />
      <Route path={ROUTES.BILLING} element={<Billing />} />
      <Route path={ROUTES.PROFILE} element={<Profile />} />
      <Route path={ROUTES.CUSTOM} element={<CustomRequest />} />
      <Route path={ROUTES.PROFILE_COMPONENT} element={<ProfileComponent />} />
      <Route path={ROUTES.EMPLOYEE_PROFILE} element={<EmployeeProfile />} />
      <Route path="*" element={<Navigate to={ROUTES.ORGANIZATION_LIST} />} />
    </Routes>
  )
}
const AdminPrivateRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.SEARCH_EMPLOYEES_RESULT} element={<SearchEmployeesResult />} />
      <Route path={ROUTES.SEARCH_EMPLOYEES} element={<SearchEmployeeDatabase />} />
      <Route path={ROUTES.SEARCH_EMPLOYEES2} element={<SearchEmployeeDatabase />} />
      <Route path={ROUTES.ORGANIZATION_CATEGORY} element={<OrganizationCategory />} />
      <Route path={ROUTES.ORGANIZATION_SETTING} element={<OrganizationSetting />} />
      <Route path={ROUTES.ORGANIZATION} element={<OrganizationSetting />} />
      <Route path={ROUTES.EMPLOYEES_LIST} element={<EmployeesList />} />
      <Route path={ROUTES.EMPLOYEE_PROFILE} element={<EmployeeProfile />} />
      <Route path={ROUTES.SETTING_BILLING} element={<SettingBilling />} />
      <Route path={ROUTES.PROFILE_COMPONENT} element={<ProfileComponent />} />
      <Route path="*" element={<Navigate to={ROUTES.SEARCH_EMPLOYEES} />} />
    </Routes>
  )
}
const UserPrivateRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.PROFILE_COMPONENT} element={<ProfileComponent />} />
      <Route path={ROUTES.SEARCH_DATABASE} element={<SearchDatabase />} />
      <Route path={ROUTES.SEARCH_DATABASE2} element={<SearchDatabase />} />
      <Route path={ROUTES.SEARCH_RESULT} element={<SearchResult />} />
      <Route path={ROUTES.USER_PROFILE} element={<UserProfile />} />
      <Route path={ROUTES.PROFILE_CONFIRMATION} element={<ProfileConfirmation />} />
      <Route path="*" element={<Navigate to={ROUTES.SEARCH_DATABASE} />} />
    </Routes>
  )
}

const PrivateRoutes = () => {
  var str = window.location.href;
  str = str.split("/");
  let role = localStorage.getItem("role")
  if (str[3]?.includes("signup")) {
    const result = `You are already logged in as a ${role == 3 ? "super admin" : role == 2 ? "organization" : "employee"} `
    if (localStorage.getItem("role") == 1) {
      localStorage.setItem("message", result)
      return (<Navigate to={ROUTES.SEARCH_DATABASE} />)
    }
    if (localStorage.getItem("role") == 2) {
      localStorage.setItem("message", result)
      return (<Navigate to={ROUTES.SEARCH_EMPLOYEES} />)
    }
    if (localStorage.getItem("role") == 3) {
      localStorage.setItem("message", result)
      return (
        <Navigate to={ROUTES.ORGANIZATION_LIST} />
      )
    }
  }

  return (
    <Layout>
      {
        localStorage.getItem("role") == 1 && <UserPrivateRoutes />
      }
      {
        localStorage.getItem("role") == 2 && <AdminPrivateRoutes />
      }
      {
        localStorage.getItem("role") == 3 && <SuperadminPrivateRoutes />
      }
    </Layout>
  )
}

function App() {
  let token = localStorage.getItem("token")
  let role = localStorage.getItem("role")
  const location = useLocation()
  const queryClient = new QueryClient();
  if (location.pathname == '/' && role == 3) {
    if (token != null) {
      return (<Navigate to={ROUTES.ORGANIZATION_LIST} />)
    }
  }
  else if (location.pathname == '/' && role == 2) {
    if (token != null) {
      return (<Navigate to={ROUTES.SEARCH_EMPLOYEES} />)
    }
  }
  else if (location.pathname == '/' && role == 1) {
    if (token != null) {
      return (<Navigate to={ROUTES.SEARCH_DATABASE} />)
    }
  }

  $(document).ready(function() {
    $('.modal-open').on('click', function() {
    $('body').css("overflow", "hidden");
    $('.modal').fadeIn();
    });
    $('.modal-close').on('click', function() {
    $('body').css("overflow", "auto");
    $('.modal').fadeOut();
    });
    });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {token != null ?
          <PrivateRoutes /> :
          <Routes>
            <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
            <Route path={ROUTES.SIGN_UP} element={<StepForm />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTES.ERROR_PAGE} element={<ErrorPage />} />
            <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} />} />
          </Routes>
        }
      </QueryClientProvider>
    </>
  );
}

export default App;
